<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex text-dark fw-bolder my-1 fs-3">Update Controller Account </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">Home</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>  
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ControllerSettings' }">Controller Settings</router-link></li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ControllerAccountList' }">Controller Account</router-link></li> 
                    <li class="breadcrumb-item text-gray-600">Update</li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/ControllerAccountList">
                    <button type="button" class="btn btn-default shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> Return back to List</span>
                    </button>
                </router-link> 
                &nbsp;
                <div class="dropdown">
                        <button class="btn btn-light-info border border-info dropdown-toggle" type="button" @click="this.isOpenActionsDropdown = !this.isOpenActionsDropdown;">
                        <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                        <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                        </svg></span>
                            Actions
                        </button>
                    <!--begin::Menu-->
                    <div class="dropdown-menu menu-rounded menu-column menu-active-bg menu-hover-bg menu-title-gray-700 fs-5 fw-semibold w-250px" :class="{ 'show': this.isOpenActionsDropdown }">
                        <div class="menu-item">
                            <div class="menu-content pb-2">
                                <span class="menu-section text-muted text-uppercase fs-7 fw-bold">Actions</span>
                            </div>
                        </div>
                        <div class="menu-item">
                            <a href="#" class="menu-link border-2  border-start border-danger" @click="deleteAccount()">
                                <span class="menu-title">Delete Controller Account</span>
                            </a>
                        </div>
                        <div class="menu-item pt-5">
                            <div class="menu-content pb-2">
                                <span class="menu-section text-muted text-uppercase fs-7 fw-bold" v-if="userData.account_type == 1 && userData.permission_type == 1">Controller Data</span>
                            </div>
                        </div>
                        <div class="menu-item">
                            <a href="#" class="menu-link border-start border-transparent" v-if="userData.account_type == 1 && userData.permission_type == 1" @click="deleteControllerAccount()">
                                <span class="menu-title">Delete Controller And Controller's Data
                                <i class="bi bi-exclamation-triangle fs-2x text-warning me-4"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4">
            <div class="row">
                <div class="tab-content" id="myTabContent">
                    <RequiredField></RequiredField>
                    <div class="card card-xxl-stretch">
                        <div class="card-header">
                            <div class="card-title">
                                <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                                    <li class="nav-item">
                                        <a class="nav-link me-1 " @click="this.activeView = 'General'" :class="{ 'text-active-primary active': activeView == 'General' }"  data-bs-toggle="tab" href="#general" target="_self">
                                            Controller Account
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link me-1" @click="this.activeView = 'Companies', getCompanies()" :class="{ 'text-active-primary active': activeView == 'Companies' }" data-bs-toggle="tab" href="#companies" target="_self">
                                            Companies
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link me-1" @click="this.activeView = 'Parameters', activeTabView = 'MailSettings'" :class="{ 'text-active-primary active': activeView == 'Parameters' }" data-bs-toggle="tab" href="#parameters" target="_self">
                                            Parameters
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="this.activeView == 'General'" class="tab-pane fade show active" id="general" role="tabpanel"> 
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-6 d-flex flex-column flex-row-auto">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0">
                                                <form id="kt_ecommerce_settings_general_form"
                                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                    <div class="fv-row mb-7 row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                Controller Company Name
                                                            </label>
                                                            <div class="input-group">
                                                                <input type="text" class="form-control"
                                                                    name="name" v-model="form.company_name">
                                                                <span class="input-group-text m-0" id="basic-addon2">
                                                                    <i class="fas fa-search fs-4 me-3"></i> Search 
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    Company Account ID  
                                                                </label> 
                                                                <input type="text" class="form-control"
                                                                    name="companyVkn" v-model="form.company_vkn">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    <span>Licence ID</span>
                                                                </label> 
                                                                <input type="number" class="form-control"
                                                                    name="licenceId" v-model="form.licence_id">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    Country
                                                                </label>
                                                                <Select2 v-model="form.company_country_code" :options="countryList" @select="onChangeCountry($event)" :settings="{ width: '100%' }"/>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                Region
                                                            </label>
                                                            <input class="form-control" v-model="form.company_region" placeholder="" name="Region" v-if="form.company_country_code !='GB'">
                                                            <Select2 v-model="form.company_region_select" :options="regionList" :settings="{width: '100%'}" v-if="form.company_country_code =='GB'" />
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    Currency   
                                                                </label>
                                                                <Select2 v-model="form.currency_code" :options="currencyList" :settings="{width: '100%'}"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex flex-column flex-row-auto">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0">
                                                <form id="kt_ecommerce_settings_general_form"
                                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                    <div class="fv-row mb-6 row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                <span>Primary Person</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="primaryPerson" v-model="form.primary_person">
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    <span>Mobile Phone</span>
                                                                </label>
                                                                <div class="input-group">
                                                                    <span class="input-group-text fs-7 text-primary" id="basic-addon1" v-if="form.company_country_code !== '-1'">
                                                                        {{form.company_country_code}} {{form.phone_country_code}}
                                                                    </span>
                                                                    <input type="text" class="form-control" name="mobile_phone" v-model="form.company_gsm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    <span>Land Phone</span>
                                                                </label>
                                                                <div class="input-group">
                                                                    <span class="input-group-text fs-7 text-primary" id="basic-addon1" v-if="form.company_country_code !== '-1'">
                                                                        {{form.company_country_code}} {{form.phone_country_code}}
                                                                    </span>
                                                                    <input type="text" class="form-control" name="landPhone" v-model="form.company_landphone">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row fv-plugins-icon-container">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    <span>Email</span>
                                                                </label>
                                                                <input type="email" class="form-control" name="email" v-model="form.company_email" :class="{'border border-danger': isValidEmail == false}">
                                                                <small class="text-danger" v-if="!isValidEmail && form.company_email !== null && form.company_email !== '' ">Email is not valid</small>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-5" for="onlineAccounting">
                                                                Online Accounting
                                                            </label>
                                                            <div class="form-check mt-3 mb-2">
                                                                <input class="form-check-input" type="checkbox" value="" id="onlineAccounting" v-model="form.online_accounting">
                                                            </div> 
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-5" for="onlineAccounting">
                                                                Company Online Accounting Default
                                                            </label>
                                                            <div class="form-check mt-3 mb-2">
                                                                <input class="form-check-input" type="checkbox" value="" id="companyOnlineAccountingDefault" v-model="form.company_online_accounting_default">
                                                            </div> 
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-5" for="isInsuranceModuleActive">
                                                                Consolidation?
                                                            </label>
                                                            <div class="form-check mt-3 mb-2">
                                                                <input class="form-check-input" type="checkbox" value="" id="isConsolidationActive" v-model="form.is_consolidation_active">
                                                            </div> 
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-5" for="isInsuranceModuleActive">
                                                                Insurance Module?
                                                            </label>
                                                            <div class="form-check mt-3 mb-2">
                                                                <input class="form-check-input" type="checkbox" value="" id="isInsuranceModuleActive" v-model="form.is_insurance_module_active">
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                            <div class="card-body pb-0">
                                                <form id="kt_ecommerce_settings_general_form"
                                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                    <div class="fv-row mb-6 row">                                                
                                                        <div class="col-lg-6">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    Post Code
                                                                </label>
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" name="post_code" v-model="form.company_postcode">
                                                                    <button type="button" class="btn btn-secondary" id="basic-addon2">
                                                                        <i class="fas fa-search fs-4 me-3"></i> Search 
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    Address List
                                                                </label>
                                                                <Select2 v-model="form.address_id" :options="addressList" :settings="{ width: '100%' }"/>  
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    Address
                                                                </label>
                                                                <textarea class="form-control" name="address" v-model="form.company_address"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    City
                                                                </label>
                                                                <input type="text" class="form-control" name="city" v-model="form.company_city">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>                    
                                    </div>
                                    <div class="col-lg-12 mt-0"> 
                                        <div class="d-grid gap-2"> 
                                            <button type="button" class="btn btn-lg btn-primary " @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false"> 
                                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                </svg>
                                                </span>
                                                <strong v-if="!this.isProgressing"> Update Controller Account </strong> 
                                                    <span class="indicator-progress" v-if="this.isProgressing">
                                                        Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span> 
                                            </button>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="this.activeView == 'Parameters'" class="tab-pane fade show" id="parameters" role="tabpanel">
                            <div class="card card-xxl-stretch">
                                <div class="card-header">
                                    <div class="card-title">
                                        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                                            <li class="nav-item">
                                                <a class="nav-link me-1" @click="this.activeTabView = 'MailSettings'" :class="{ 'text-active-primary active': activeTabView == 'MailSettings' }" data-bs-toggle="tab" href="#controller_mail_settings" target="_self">
                                                    Mail Settings
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link me-1" @click="this.activeTabView = 'LicenceParameters', getLicenceParameters()" :class="{ 'text-active-primary active': activeTabView == 'LicenceParameters' }" data-bs-toggle="tab" href="#licence_parameters" target="_self">
                                                    Licence Parameters
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link me-1" @click="this.activeTabView = 'LicenceBankDescription'" :class="{ 'text-active-primary active': activeTabView == 'LicenceBankDescription' }" data-bs-toggle="tab" href="#licence_bank_description" target="_self">
                                                    Licence Bank Description
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link me-1" @click="this.activeTabView = 'ReportDesign', getDocumentDesigner()" :class="{ 'text-active-primary active': activeTabView == 'ReportDesign' }" data-bs-toggle="tab" href="#report_design" target="_self">
                                                    Report Designs
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link me-1" @click="this.activeTabView = 'APIKeys', getApiKeys()" :class="{ 'text-active-primary active': activeTabView == 'APIKeys' }" data-bs-toggle="tab" href="#api_keys" target="_self">
                                                    API Keys
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link me-1" @click="this.activeTabView = 'RateTypes', getRateLists()" :class="{ 'text-active-primary active': activeTabView == 'RateTypes' }" data-bs-toggle="tab" href="#rate_type_list" target="_self">
                                                    Rate Type List
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link me-1" @click="this.activeTabView = 'DataImport', getDataImportTemplates()" :class="{ 'text-active-primary active': activeTabView == 'DataImport' }" data-bs-toggle="tab" href="#data_import_list" target="_self">
                                                    Data Import
                                                </a>
                                            </li>
                                            <li class="nav-item" v-if="form.is_insurance_module_active">
                                                <a class="nav-link me-1" @click="this.activeTabView = 'PolicyParameters', getPolicyParameters()" :class="{ 'text-active-primary active': activeTabView == 'PolicyParameters' }" data-bs-toggle="tab" href="#data_import_list" target="_self">
                                                    Policy Parameters
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div v-if="this.activeTabView == 'MailSettings'" class="tab-pane fade show" id="controller_mail_settings" role="tabpanel">
                                            <MailSettings ref="MailSettings" :mail-type="2" :account-id="form.id"></MailSettings>
                                        </div>                            
                                        <div v-if="this.activeTabView == 'LicenceParameters'" class="tab-pane fade show" id="licence_parameters" role="tabpanel">                                
                                            <div class="card">
                                                <div class="card-header px-0 border-0">
                                                    <div class="card-title">
                                                    </div>
                                                    <div class="card-toolbar">
                                                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                                                            <button type="button" class="btn btn-light-primary border border-primary" @click="getLicenceParameters()" data-kt-indicator="on">
                                                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                                                </svg>
                                                                </span>
                                                                <strong v-if="!this.isListProgressing"> List Licence Parameters </strong> 
                                                                <span class="indicator-progress" v-if="this.isListProgressing">
                                                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span> 
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body px-0">
                                                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                        <div class="table-responsive min-h-300px">
                                                            <DxDataGrid :show-borders="true" key-expr="id" :data-source="licenceParameterList" 
                                                                :allow-column-reordering="true"  
                                                                :allow-column-resizing="true"
                                                                :column-auto-width="true"
                                                                :row-alternatin-enabled="true"
                                                                :hover-state-enabled="true"
                                                                :show-row-lines="true" 
                                                                @exporting="this.$onExporting($event , 'Controller-licence_parameters')"> 
                                                                <DxFilterRow :visible="true"/>
                                                                <DxHeaderFilter :visible="true"/>
                                                                <DxGroupPanel :visible="true"/>      
                                                                <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                                <DxPaging :page-size="20"/>
                                                                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                                <DxSearchPanel :visible="true"/> 
                                                                <DxScrolling
                                                                    mode="virtual"
                                                                    row-rendering-mode="virtual"
                                                                    
                                                                />
                                                                <DxColumn data-field="is_active" :width="75" caption="Is Active?" cell-template="is_active-cell-template" :allow-filtering="false" alignment="center"/>
                                                                <DxColumn data-field="licence_order" :width="50" caption="Order" :allow-filtering="false" cell-template="licence_order-cell-template" />
                                                                <DxColumn data-field="is_sub_or_main" :width="62" caption="Sub/Main" header-cell-template="is_sub_or_main-header" cell-template="is_sub_or_main-cell-template"/> 
                                                                <DxColumn data-field="parameter_type_name" caption="Parameter Type" header-cell-template="parameter_type-header" cell-template="parameter_type-cell-template"/> 
                                                                <DxColumn data-field="licence_type_name" caption="Licence Type" header-cell-template="licence_type-header" cell-template="licence_type-cell-template" /> 
                                                                <DxColumn data-field="package_type_name" caption="Package Type" header-cell-template="package_type-header" cell-template="package_type-cell-template" />  
                                                                <DxColumn data-field="price" caption="" alignment="right" header-cell-template="price-header" cell-template="price-cell-template" />  
                                                                <DxColumn data-field="user_price" caption="" alignment="right" header-cell-template="user_price-header" cell-template="user_price-cell-template" /> 
                                                                <DxColumn data-field="vat_rate" caption="" alignment="right" header-cell-template="vat_rate-header" cell-template="vat_rate-cell-template" /> 
                                                                <DxColumn data-field="user_count" caption="User Count" alignment="right" header-cell-template="user_count-header" cell-template="user_count-cell-template"/>
                                                                <DxColumn data-field="period_day" caption="Period of Use" alignment="right" header-cell-template="period_of_use-header" cell-template="period_of_use-cell-template" /> 
                                                                <DxColumn data-field="is_liabilities_included" caption="Liab." alignment="left" :allow-filtering="false" cell-template="is_liabilities_included-cell-template" />  
                                                                <DxColumn data-field="is_documents_included" caption="Doc." alignment="left" :allow-filtering="false" cell-template="is_documents_included-cell-template" />
                                                                <DxColumn data-field="is_company_inquiry_active" caption="Company Inquiry" header-cell-template="is_company_inquiry_active-header" alignment="left" :allow-filtering="false" cell-template="is_company_inquiry_active-cell-template" />
                                                                <DxColumn data-field="is_address_inquiry_active" caption="Address Inquiry" header-cell-template="is_address_inquiry_active-header" alignment="left" :allow-filtering="false" cell-template="is_address_inquiry_active-cell-template" />

                                                                
                                                                <template #is_sub_or_main-header>
                                                                    <div>Sub/</div><div>Main</div>
                                                                </template>
                                                                <template #parameter_type-header>
                                                                    <div>Parameter</div><div>Type</div>
                                                                </template>
                                                                <template #licence_type-header>
                                                                    <div>Licence</div><div>Type</div>
                                                                </template>
                                                                <template #package_type-header>
                                                                    <div>Package</div><div>Type</div>
                                                                </template>
                                                                <template #user_count-header>
                                                                    <div>User</div><div>Count</div>
                                                                </template>
                                                                <template #period_of_use-header>
                                                                    <div>Period</div><div>of Use</div>
                                                                </template>
                                                                <template #price-header>
                                                                    <div>Licence</div><div>Price</div>
                                                                </template>
                                                                <template #user_price-header>
                                                                    <div>User</div><div>Price</div>
                                                                </template>
                                                                <template #vat_rate-header>
                                                                    <div>Add Vat?</div><div>/Rate</div>
                                                                </template>
                                                                <template #is_company_inquiry_active-header>
                                                                    <div>Comp.</div><div>Inquiry</div>
                                                                </template>
                                                                <template #is_address_inquiry_active-header>
                                                                    <div>Addr.</div><div>Inquiry</div>
                                                                </template>
                                                                
                                                                <template #is_active-cell-template="{ data }"> 
                                                                    <span>
                                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                            <input class="form-check-input" type="checkbox" v-model="data.data.is_active" @change="onChangeIsActive(data.data)"/>
                                                                        </div>
                                                                    </span>
                                                                </template>
                                                                <template #is_sub_or_main-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            {{data.data.is_sub_or_main}}
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #parameter_type-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            {{ data.data.parameter_type_name }}
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #licence_type-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            {{ data.data.licence_type_name }}
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #package_type-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            {{ data.data.package_type_name }}
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #is_liabilities_included-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder align-middle text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            <div v-if="data.data.parameter_type !== 5">
                                                                                <i v-if="data.data.is_liabilities_included == 1" class="bi bi-check-circle fs-5 text-success"></i>
                                                                                <i v-if="data.data.is_liabilities_included == 0" class="bi bi-x-circle fs-5 text-danger"></i>
                                                                            </div>
                                                                            <div v-if="data.data.parameter_type == 5">-</div>
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #is_documents_included-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder align-middle text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            <div v-if="data.data.parameter_type !== 5">
                                                                                <i v-if="data.data.is_documents_included == 1" class="bi bi-check-circle fs-5 text-success"></i> 
                                                                                <div v-if="data.data.is_documents_included == 1" class="fs-8 text-gray-600">Limit: {{data.data.document_limit}}</div>
                                                                                <i v-if="data.data.is_documents_included == 0" class="bi bi-x-circle fs-5 text-danger"></i>
                                                                            </div>
                                                                            <div v-if="data.data.parameter_type == 5">-</div>
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #user_count-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            <span v-if="data.data.parameter_type !== 5">{{ data.data.user_count }}</span>
                                                                            <span v-if="data.data.parameter_type == 5">-</span>
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #period_of_use-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            <span v-if="data.data.parameter_type !== 5">{{ data.data.period_day }}</span>
                                                                            <span v-if="data.data.parameter_type == 5">-</span>
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #price-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder text-end text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            <div v-if="data.data.parameter_type !== 5">{{ data.data.price }}/Mon</div>
                                                                            <div v-if="data.data.parameter_type !== 5">{{data.data.price_annual}}/Ann</div>
                                                                            <span v-if="data.data.parameter_type == 5">-</span>
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #user_price-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder text-end text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            <div v-if="data.data.parameter_type !== 5">{{ data.data.user_price }}/Mon</div>
                                                                            <div v-if="data.data.parameter_type !== 5">{{ data.data.user_price_annual }}/Ann</div>
                                                                            <span v-if="data.data.parameter_type == 5">-</span>
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #vat_rate-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder text-end text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            <div v-if="data.data.parameter_type !== 5">
                                                                                <span v-if="data.data.is_add_vat == 1">{{data.data.vat_rate}}</span>
                                                                                <span v-else>None</span>
                                                                            </div>
                                                                            <span v-if="data.data.parameter_type == 5">-</span>
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #licence_order-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            {{ data.data.licence_order }}
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #is_company_inquiry_active-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder align-middle text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            <div v-if="data.data.parameter_type !== 5">
                                                                                <i v-if="data.data.is_company_inquiry_active == 1" class="bi bi-check-circle fs-5 text-success"></i> 
                                                                                <div v-if="data.data.is_company_inquiry_active == 1" class="fs-8 text-gray-600">Limit: {{data.data.company_inquiry_rate_limit}}</div>
                                                                                <i v-if="data.data.is_company_inquiry_active == 0" class="bi bi-x-circle fs-5 text-danger"></i>
                                                                            </div>
                                                                            <div v-if="data.data.parameter_type == 5">-</div>
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                                <template #is_address_inquiry_active-cell-template="{ data }">
                                                                    <span>
                                                                        <a href="#" class="text-gray-800 fw-bolder align-middle text-hover-primary mb-1" data-bs-toggle="modal" 
                                                                            data-bs-target="#kt_modal_add_licence_parameter" @click="openEditLicenceParameterModal(data.data.id)"  ref="btnEditItem">
                                                                            <div v-if="data.data.parameter_type !== 5">
                                                                                <i v-if="data.data.is_address_inquiry_active == 1" class="bi bi-check-circle fs-5 text-success"></i> 
                                                                                <div v-if="data.data.is_address_inquiry_active == 1" class="fs-8 text-gray-600">Limit: {{data.data.address_inquiry_rate_limit}}</div>
                                                                                <i v-if="data.data.is_address_inquiry_active == 0" class="bi bi-x-circle fs-5 text-danger"></i>
                                                                            </div>
                                                                            <div v-if="data.data.parameter_type == 5">-</div>
                                                                        </a>
                                                                    </span>
                                                                </template>
                                                            </DxDataGrid>     
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div> 
                                        <div v-if="this.activeTabView == 'LicenceBankDescription'" class="tab-pane fade show" id="licence_bank_description" role="tabpanel">                                
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label">
                                                        <span>Licence Bank Description</span>
                                                    </label>
                                                    <div class="widget-container">
                                                        <Editor v-model="form.licence_bank_description"
                                                            :api-key="htmlEditorKey"
                                                            :init="{
                                                                inline: false,
                                                                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage textcolor colorpicker',
                                                                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | fullscreen  preview  print | insertfile image media pageembed link anchor | a11ycheck ltr rtl | showcomments addcomment | uploadimage',
                                                                min_height: 200, 
                                                                valid_elements: '*[*]',
                                                                extended_valid_elements: '*[*]',
                                                                valid_children: '+a[div|h1|h2|h3|h4|h5|h6|p|#text]',
                                                                custom_elements: 'style,link,~link',
                                                                force_br_newlines: true,
                                                                forced_root_block: false,
                                                                relative_urls: false,
                                                                remove_script_host: false,
                                                                convert_urls: false,
                                                                fontsize_formats: '8pt 10pt 11pt 12pt 13pt 14pt 18pt 20pt 24pt 32pt 36pt 40pt 44pt 46pt 48pt 50pt 54pt 60pt 72pt 76pt 80pt 84pt 86pt 90pt 100pt',
                                                                allow_html_in_named_anchor: true,
                                                                allow_unsafe_link_target: true,
                                                            }"/>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 mt-10"> 
                                                    <div class="d-grid gap-2"> 
                                                        <button type="button" class="btn btn-lg btn-primary " @click="this.saveData()" data-kt-indicator="on"> 
                                                            <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                            </svg>
                                                            </span>
                                                            <strong v-if="!this.isProgressing"> Save Licence Bank Description </strong> 
                                                                <span class="indicator-progress" v-if="this.isProgressing">
                                                                    Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span> 
                                                        </button>
                                                    </div> 
                                                </div>                                            
                                            </div>                                                                            
                                        </div>    
                                        <div v-if="this.activeTabView == 'ReportDesign'" class="tab-pane fade show" id="report_design" role="tabpanel">                                
                                            <div class="card">
                                                <div class="row py-3">
                                                    <div class="col-lg-9">
                                                        <label class="fs-6 fw-bold form-label">
                                                            Report Designs
                                                        </label>
                                                        <div class="input-group mb-5">
                                                            <Select2 v-model="designForm.report_design_id" :options="reportDesignList" :settings="{ width: '400px' }" />  
                                                            <button class="btn btn-light-primary btn-sm btn-sm border border-primary" type="button" @click="addReportDesign();" :disabled="designForm.report_design_id == '-1'">
                                                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                                                </svg>
                                                                </span>
                                                                Add
                                                            </button> 
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <div class="d-flex justify-content-end mt-6" data-kt-customer-table-toolbar="base">
                                                            <button type="button" class="btn btn-light-primary border border-primary"  @click="getDocumentDesigner()" data-kt-indicator="on">
                                                                <span class="svg-icon svg-icon-muted svg-icon-2">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                                                                    <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                                                                    </svg>
                                                                </span>
                                                                <strong v-if="!this.isListProgressing"> List Design </strong> 
                                                                <span class="indicator-progress" v-if="this.isListProgressing">
                                                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span> 
                                                            </button>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div class="card-body px-0">
                                                    <div class="table-responsive min-h-300px">
                                                        <DxDataGrid 
                                                            :show-borders="true" 
                                                            key-expr="id" 
                                                            :data-source="controllerReportDesignList" 
                                                            :allow-column-reordering="true" 
                                                            :allow-column-resizing="true" 
                                                            :column-auto-width="true" 
                                                            :row-alternatin-enabled="true"
                                                            :hover-state-enabled="true" 
                                                            :show-row-lines="true" 
                                                            @exporting="this.$onExporting($event , 'Controller-report-design-list')"> 
                                                        <DxFilterRow :visible="true"/>
                                                        <DxHeaderFilter :visible="true"/>
                                                        <DxGroupPanel :visible="true"/>      
                                                        <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                        <DxPaging :page-size="50"/>
                                                        <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                        <DxSearchPanel :visible="true"/>
                                                        <DxScrolling
                                                            mode="virtual"
                                                            row-rendering-mode="virtual"
                                                            
                                                        />
                                                        <DxColumn data-field="is_default" caption="" :allow-filtering="false" :width="100" cell-template="is_default-cell-template" :allow-exporting="false" /> 
                                                        <DxColumn data-field="name" caption="Name" cell-template="name-cell-template"/> 
                                                        <DxColumn data-field="category_name" caption="Category" cell-template="category-cell-template"/> 
                                                        <DxColumn data-field="receipt_type_name" caption="Receipt Type" cell-template="receipt_type_name-cell-template"/> 
                                                        <DxColumn data-field="view_option_name" caption="View Option" cell-template="view_option_name-cell-template"/> 
                                                        <DxColumn data-field="created_at" caption="Created At"  cell-template="created_at-cell-template" alignment="right"/>
                                                        <DxColumn data-field="delete" caption="" :width="100"  cell-template="delete-cell-template" :allow-exporting="false"/>
                                                            
                                                                <!-- Cell Templates -->
                                                        <template #is_default-cell-template="{ data }">
                                                        <span>
                                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                <input class="form-check-input" type="checkbox" v-model="data.data.is_default" @change="onChangeIsDefault(data.data)" />
                                                            </div>
                                                        </span>
                                                        </template>
                                                        <template #name-cell-template="{ data }">
                                                            <span class="text-gray-900 fw-bold mb-1">
                                                                {{ data.data.name }}
                                                            </span>
                                                        </template>
                                                        <template #category-cell-template="{ data }">
                                                            <span class="text-gray-900 fw-bold mb-1">{{ data.data.category_name }} </span>
                                                        </template>
                                                        <template #receipt_type_name-cell-template="{ data }">
                                                            <span class="text-gray-900 fw-bold mb-1" v-if="data.data.category == 1">-</span>
                                                            <span class="text-gray-900 fw-bold mb-1" v-if="data.data.category == 2">{{data.data.receipt_type_name}}</span>
                                                        </template>
                                                        <template #view_option_name-cell-template="{ data }">
                                                            <span class="text-gray-900 fw-bold mb-1">
                                                                {{data.data.view_option_name}}
                                                            </span>
                                                        </template>
                                                        <template #created_at-cell-template="{ data }">
                                                        <span class="text-gray-900 fw-bold mb-1">
                                                                {{ previewDate(data.data.created_at) }}
                                                            </span>
                                                        </template>
                                                        <template #delete-cell-template="{ data }">
                                                            <div class="d-grid gap-4 d-md-flex justify-content-md-center">
                                                                <button class="btn btn-danger btn-xs" type="button"  @click="deleteDesign(data.data.id)">Delete</button>
                                                            </div>
                                                        </template>                                                    
                                                        </DxDataGrid>     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                        <div v-if="this.activeTabView == 'APIKeys'" class="tab-pane fade show" id="api_keys" role="tabpanel">
                                            <div class="content flex-row-fluid" id="kt_content">
                                                <div class="card">
                                                    <div class="card-header border-0 px-0">
                                                        <div class="card-title">
                                                            <div class="d-flex align-items-center position-relative my-1">
                                                                <button href="#"  @click="openCreateApiKeyModal(this.form.id, 'addItem')" data-bs-toggle="modal"
                                                                        data-bs-target="#kt_modal_add_api_key" type="button" class="btn btn-light-primary border border-primary me-3">
                                                                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                                                    </svg>
                                                                    </span>
                                                                    Add API Key
                                                                </button>                 
                                                            </div>
                                                        </div>
                                                        <div class="card-toolbar">
                                                            <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                                                                <button type="button" class="btn btn-light-primary border border-primary" @click="getApiKeys()" data-kt-indicator="on">
                                                                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                                                    </svg>
                                                                    </span>
                                                                    <strong v-if="!this.isListProgressing"> List API Keys </strong> 
                                                                    <span class="indicator-progress" v-if="this.isListProgressing">
                                                                        Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                    </span> 
                                                                </button>
                                                            </div>
                                                            <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                                                                <div class="fw-bolder me-5">
                                                                <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                                                                <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-body px-0">
                                                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                            <div class="table-responsive min-h-300px">
                                                                <DxDataGrid :show-borders="true" key-expr="id" :data-source="apiKeyFormList" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                                                                    data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'API-definition-list')"> 
                                                                    <DxFilterRow :visible="true"/>
                                                                    <DxHeaderFilter :visible="true"/>
                                                                    <DxGroupPanel :visible="true"/>      
                                                                    <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                                    <DxPaging :page-size="20"/>
                                                                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                                    <DxSearchPanel :visible="true"/>
                                                                    <DxScrolling
                                                                        mode="virtual"
                                                                        row-rendering-mode="virtual"
                                                                        
                                                                    />
                                                                    <DxColumn data-field="type_name" caption="API Type"/> 
                                                                    <DxColumn data-field="api_url" caption="API Url"/>   
                                                                    <DxColumn data-field="api_key" caption="API Key" /> 
                                                                    <DxColumn data-field="client_id" caption="Client ID" /> 
                                                                    <DxColumn data-field="primary_key" caption="Primary Key" />
                                                                    <DxColumn data-field="sub_type_name" caption="Sub Type" />
                                                                    <DxColumn data-field="is_active" caption="Is Active"/>  
                                                                    <DxColumn data-field="is_default" caption="Is Default"/>  
                                                                    <DxColumn data-field="description" caption="Description"/>  

                                                                    <template #dataRowTemplate="{ data: data }">
                                                                        <td class="text-gray-800">
                                                                            <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" data-bs-toggle="modal"
                                                                        data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                                                {{ data.data.type_name }}
                                                                            </a>
                                                                        </td> 
                                                                        <td class="text-gray-800">
                                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                                                        data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                                                {{data.data.api_url}}
                                                                            </a>
                                                                        </td> 
                                                                        <td class="text-gray-800">
                                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                                                        data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                                            {{data.data.api_key}}
                                                                            </a>
                                                                        </td>  
                                                                        <td class="text-gray-800">
                                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                                                        data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                                            {{data.data.client_id}}
                                                                            </a>
                                                                        </td>  
                                                                        <td class="text-gray-800">
                                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                                                        data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                                                {{data.data.primary_key}}
                                                                            </a>
                                                                        </td>  
                                                                        <td class="text-gray-800">
                                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                                                        data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                                                {{data.data.sub_type_name}}
                                                                            </a>
                                                                        </td>  
                                                                        <td class="text-gray-800">
                                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                                                        data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                                                            <span v-if="data.data.is_active == 1">
                                                                                <i class="las la-check fs-2x text-success"></i>
                                                                            </span>  
                                                                            <span v-if="data.data.is_active == 0">
                                                                                <i class="las la-times fs-2x text-danger"></i>
                                                                            </span> 
                                                                        </a>
                                                                            </a>
                                                                        </td>
                                                                        <td class="text-gray-800">
                                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                                                        data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                                                            <span v-if="data.data.is_default == 1">
                                                                                <i class="las la-check fs-2x text-success"></i>
                                                                            </span>  
                                                                            <span v-if="data.data.is_default == 0">
                                                                                <i class="las la-times fs-2x text-danger"></i>
                                                                            </span> 
                                                                        </a>
                                                                            </a>
                                                                        </td>
                                                                        <td class="text-gray-800">
                                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                                                        data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                                            {{ data.data.description }}
                                                                            </a>
                                                                        </td>
                                                                    </template>
                                                                </DxDataGrid>     
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>     
                                        <div v-if="this.activeTabView == 'RateTypes'" class="tab-pane fade show" id="rate_type_list" role="tabpanel">                                
                                            <div class="card">
                                                <div class="row py-3">
                                                    <div class="col-lg-9">
                                                        <label class="fs-6 fw-bold form-label">
                                                            Rate Types
                                                        </label>
                                                        <div class="input-group mb-5">
                                                            <Select2 v-model="rateForm.rate_type_id" :options="rateList" :settings="{ width: '400px' }" />  
                                                            <button class="btn btn-light-primary btn-sm btn-sm border border-primary" type="button" @click="addRateTypeToList();">
                                                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                                                </svg>
                                                                </span>
                                                                Add
                                                            </button> 
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <div class="d-flex justify-content-end mt-6" data-kt-customer-table-toolbar="base">
                                                            <button type="button" class="btn btn-light-primary border border-primary"  @click="getRateTypes()" data-kt-indicator="on">
                                                                <span class="svg-icon svg-icon-muted svg-icon-2">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                                                                    <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                                                                    </svg>
                                                                </span>
                                                                <strong v-if="!this.isListProgressing"> List Rate Types </strong> 
                                                                <span class="indicator-progress" v-if="this.isListProgressing">
                                                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span> 
                                                            </button>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div class="card-body px-0">
                                                    <div class="table-responsive min-h-300px">
                                                        <DxDataGrid 
                                                            :show-borders="true" 
                                                            key-expr="id" 
                                                            :data-source="controllerRateTypeList" 
                                                            :allow-column-reordering="true" 
                                                            :allow-column-resizing="true" 
                                                            :column-auto-width="true" 
                                                            :row-alternatin-enabled="true"
                                                            :hover-state-enabled="true" 
                                                            :show-row-lines="true" 
                                                            @exporting="this.$onExporting($event , 'Controller-rate-type-list')"> 
                                                        <DxFilterRow :visible="true"/>
                                                        <DxHeaderFilter :visible="true"/>
                                                        <DxGroupPanel :visible="true"/>      
                                                        <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                        <DxPaging :page-size="50"/>
                                                        <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                        <DxSearchPanel :visible="true"/>
                                                        <DxScrolling
                                                            mode="virtual"
                                                            row-rendering-mode="virtual"
                                                            
                                                        />
                                                        <DxColumn data-field="is_default" caption="" :allow-filtering="false" :width="100" cell-template="is_default-cell-template" />
                                                        <DxColumn data-field="rate_type_name" caption="Name" cell-template="name-cell-template"/> 
                                                        <DxColumn data-field="created_at" caption="Created At"  cell-template="created_at-cell-template" alignment="right"/>
                                                        <DxColumn data-field="delete" caption="" :width="100"  cell-template="delete-cell-template" :allow-exporting="false"/>
                                                            
                                                                <!-- Cell Templates -->
                                                        <template #is_default-cell-template="{ data }">
                                                        <span>
                                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                <input class="form-check-input" type="checkbox" v-model="data.data.is_default" @change="onChangeDefaultRateList(data.data)" />
                                                            </div>
                                                        </span>
                                                        </template>
                                                        <template #name-cell-template="{ data }">
                                                            <span class="text-gray-900 fw-bold mb-1">
                                                                {{ data.data.rate_type_name }}
                                                            </span>
                                                        </template>
                                                        <template #created_at-cell-template="{ data }">
                                                        <span class="text-gray-900 fw-bold mb-1">
                                                                {{ previewDate(data.data.created_at) }}
                                                            </span>
                                                        </template>
                                                        <template #delete-cell-template="{ data }">
                                                            <div class="d-grid gap-4 d-md-flex justify-content-md-center">
                                                                <button class="btn btn-danger btn-xs" type="button"  @click="deleteRateList(data.data.id)">Delete</button>
                                                            </div>
                                                        </template>                                                    
                                                        </DxDataGrid>     
                                                    </div>                                        
                                                    <div class="separator separator-dashed my-5 mt-0 border-gray-300"></div>
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label ">
                                                                    Currency Search Type & Calculation Method
                                                                </label>
                                                                <Select2 v-model="form.currency_search_type" :options="currencySearchlist" :settings="{ width: '100%' }"/>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 mt-5"> 
                                                            <div class="d-grid gap-2"> 
                                                                <button type="button" class="btn btn-lg btn-primary " @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false"> 
                                                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                                    </svg>
                                                                    </span>
                                                                    <strong v-if="!this.isProgressing"> Update Controller Account </strong> 
                                                                        <span class="indicator-progress" v-if="this.isProgressing">
                                                                            Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                        </span> 
                                                                </button>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="this.activeTabView == 'DataImport'" class="tab-pane fade show" id="data_import_list" role="tabpanel">                                
                                            <div class="card">
                                                <div class="row">
                                                    <div class="col-3">
                                                        <label class="fs-6 fw-bold form-label">
                                                            Category
                                                        </label>
                                                        <Select2 v-model="dataImportForm.category" :options="dataImportCategoryList" @select="getDataImportTemplates()" :settings="{ width: '100%' }" />  
                                                    </div>
                                                    <div class="col-6">
                                                        <label class="fs-6 fw-bold form-label">
                                                            Templates
                                                        </label>
                                                        <div class="input-group">
                                                            <Select2 v-model="dataImportForm.template_id" :options="dataImportTemplateList" :settings="{ width: '300px' }" />  
                                                            <button class="btn btn-light-primary btn-sm btn-sm border border-primary" type="button" @click="addDataImportToList();">
                                                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                                                </svg>
                                                                </span>
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="d-flex justify-content-end mt-6" data-kt-customer-table-toolbar="base">
                                                            <button type="button" class="btn btn-light-primary border border-primary"  @click="getDataImportTemplates()" data-kt-indicator="on">
                                                                <span class="svg-icon svg-icon-muted svg-icon-2">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                                                                    <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                                                                    </svg>
                                                                </span>
                                                                <strong v-if="!this.isListProgressing"> List Templates </strong> 
                                                                <span class="indicator-progress" v-if="this.isListProgressing">
                                                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span> 
                                                            </button>
                                                        </div>
                                                    </div> 
                                                </div>
                                                <div class="card-body px-0">
                                                    <div class="table-responsive min-h-300px">
                                                        <DxDataGrid 
                                                            :show-borders="true" 
                                                            key-expr="id" 
                                                            :data-source="controllerDataImportList" 
                                                            :allow-column-reordering="true" 
                                                            :allow-column-resizing="true" 
                                                            :column-auto-width="true" 
                                                            :row-alternatin-enabled="true"
                                                            :hover-state-enabled="true" 
                                                            :show-row-lines="true" 
                                                            @exporting="this.$onExporting($event , 'Controller-data-import-list')"> 
                                                        <DxFilterRow :visible="true"/>
                                                        <DxHeaderFilter :visible="true"/>
                                                        <DxGroupPanel :visible="true"/>      
                                                        <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                        <DxPaging :page-size="50"/>
                                                        <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                        <DxSearchPanel :visible="true"/>
                                                        <DxScrolling
                                                            mode="virtual"
                                                            row-rendering-mode="virtual"
                                                            
                                                        />
                                                        <DxColumn data-field="is_default" caption="" :allow-filtering="false" :width="100" cell-template="is_default-cell-template" /> 
                                                        <DxColumn data-field="category_name" caption="Category" cell-template="category-template"/>
                                                        <DxColumn data-field="template_name" caption="Name" cell-template="template_name-template"/>
                                                        <DxColumn data-field="delete" caption="" :width="100" cell-template="delete-cell-template" :allow-exporting="false"/>
                                                            
                                                        <!-- Cell Templates -->
                                                        <template #is_default-cell-template="{ data }">
                                                        <span>
                                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                <input class="form-check-input" type="checkbox" v-model="data.data.is_default" @change="onChangeImportTemplate(data.data)" />
                                                            </div>
                                                        </span>
                                                        </template>
                                                        <template #category-template="{ data }">
                                                            <span class="text-gray-900 fw-bold mb-1">
                                                                {{ data.data.category_name }}
                                                            </span>
                                                        </template>
                                                        <template #template_name-template="{ data }">
                                                            <span class="text-gray-900 fw-bold mb-1">
                                                                {{ data.data.template_name }}
                                                            </span>
                                                        </template>
                                                        <template #created_at-cell-template="{ data }">
                                                        <span class="text-gray-900 fw-bold mb-1">
                                                                {{ previewDate(data.data.created_at) }}
                                                            </span>
                                                        </template>
                                                        <template #delete-cell-template="{ data }">
                                                            <div class="d-grid gap-4 d-md-flex justify-content-md-center">
                                                                <button class="btn btn-danger btn-xs" type="button"  @click="deleteImportTemplate(data.data.id)">Delete</button>
                                                            </div>
                                                        </template>                                                    
                                                        </DxDataGrid>     
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="tab-content" id="myTabContent">
                                                    <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card card-xxl-stretch bg-lighten border border-gray-300">
                                                                    <div class="card-body pb-0">
                                                                        <form id="kt_ecommerce_settings_general_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                                                            <div class="fv-row mb-7 row">
                                                                                <div class="form-check text-center">
                                                                                    <div class="d-flex justify-content-center align-items-center">
                                                                                        <i class="fas fa-file-excel fs-3x ms-2 px-4"></i>
                                                                                        <button class="btn btn-primary btn-sm p-3 mt-1" data-bs-toggle="modal" data-bs-target="#iconmodal">
                                                                                            <span>Download Sample Template</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <small class="text-center d-block mt-2">You Can Download Sample Template Here</small>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>   
                                        <div v-if="this.activeTabView == 'PolicyParameters'" class="tab-pane fade show" id="data_import_list" role="tabpanel">
                                            <div class="card border">
                                                <div class="card-header">
                                                    <h3 class="card-title">Tax Payment Methods</h3>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label">
                                                                Tax
                                                            </label>
                                                            <Select2 v-model="taxForm.tax_method" :options="taxMethodList" :settings="{ width: '100%' }" />
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label">
                                                                Sub Tax 1
                                                            </label>
                                                            <Select2 v-model="taxForm.sub_tax_1_method" :options="taxMethodList" :settings="{ width: '100%' }" />
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                Sub Tax 2
                                                            </label>
                                                            <Select2 v-model="taxForm.sub_tax_2_method" :options="taxMethodList" :settings="{ width: '100%' }" />
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                Sub Tax 3
                                                            </label>
                                                            <Select2 v-model="taxForm.sub_tax_3_method" :options="taxMethodList" :settings="{ width: '100%' }" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer p-3">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="d-grid gap-2"> 
                                                                <button type="button" class="btn btn-lg btn-primary " @click="this.savePolicyParameters()" data-kt-indicator="on">
                                                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                                    </svg>
                                                                    </span>
                                                                        <strong v-if="!this.isProgressing"> Save Policy Parameters </strong> 
                                                                        <span class="indicator-progress" v-if="this.isProgressing">
                                                                            Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                        </span> 
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div v-if="this.activeView == 'Companies'" class="tab-pane fade show active" id="companies" role="tabpanel"> 
                            <div class="card card-xxl-stretch">                                
                                <div class="card-header border-0">
                                    <div class="card-title">
                                    </div>
                                    <div class="card-toolbar">
                                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                                            <button type="button" class="btn btn-light-primary border border-primary" @click="getCompanies()" data-kt-indicator="on">
                                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                                </svg>
                                                </span>
                                                <strong v-if="!this.isListProgressing"> List Company Accounts </strong> 
                                                <span class="indicator-progress" v-if="this.isListProgressing">
                                                    Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span> 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <DxDataGrid :show-borders="true" key-expr="id" :data-source="companyList" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                                            data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Company-list')"> 
                                            <DxFilterRow :visible="true"/>
                                            <DxHeaderFilter :visible="true"/>
                                            <DxGroupPanel :visible="true"/>      
                                            <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                            <DxPaging :page-size="20"/>
                                            <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                            <DxSearchPanel :visible="true"/>
                                            <DxScrolling
                                                mode="virtual"
                                                row-rendering-mode="virtual"
                                                
                                            />
                                            <DxColumn data-field="company_vkn" caption="Company Number" /> 
                                            <DxColumn data-field="company_name" caption="Company Name"/> 
                                            <DxColumn data-field="company_type_name" caption="Company Type Name" header-cell-template="company_type_name-header"/> 
                                            <DxColumn data-field="account_office_reference" caption="Acc.Office Ref."/> 
                                            <DxColumn data-field="controller_account_name" caption="Controller Account" header-cell-template="controller_account_name-header"/> 
                                            <DxColumn data-field="primary_person" caption="Primary Person" header-cell-template="primary_person-header"/> 
                                            <DxColumn data-field="company_gsm" caption="Mobile" /> 
                                            <DxColumn data-field="company_email" caption="Email" /> 
                                            <DxColumn data-field="company_country_code" caption="Country" />
                                            <DxColumn data-field="system_access_name" caption="System Access Account" header-cell-template="system_access_name-header" />
                                            <DxColumn data-field="controller_access_name" caption="Controller Access Account"  header-cell-template="controller_access_name-header" />
                                            <DxColumn data-field="created_at" caption="Created At" data-type="date" alignment="right" format="dd.MM.yyyy" />

                                            <template #controller_account_name-header>
                                                <div> Controller </div> <div> Account</div>
                                            </template>
                                            <template #primary_person-header>
                                                <div> Primary </div> <div> Person</div>
                                            </template>
                                            <template #system_access_name-header>
                                                <div> System </div> <div> Access Account</div>
                                            </template>
                                            <template #controller_access_name-header>
                                                <div> Controller </div> <div> Access Account</div>
                                            </template>
                                            <template #company_type_name-header>
                                                <div> Company </div> <div> Type Name</div>
                                            </template>

                                            <template #dataRowTemplate="{ data: data }">
                                                <td class="text-gray-800 cursor-pointer" @click="updateItem(data.data.id)">
                                                    {{ data.data.company_vkn }}
                                                </td>
                                                <td class="text-gray-800 fw-bold cursor-pointer" @click="updateItem(data.data.id)">
                                                    {{ data.data.company_name }}
                                                </td>
                                                <td class="text-gray-800 fw-bold cursor-pointer" @click="updateItem(data.data.id)">
                                                    {{ data.data.company_type_name }}
                                                </td>
                                                <td class="text-gray-800 cursor-pointer" @click="updateItem(data.data.id)">
                                                    {{ data.data.account_office_reference }}
                                                </td>
                                                <td class="text-gray-800 cursor-pointer" @click="updateItem(data.data.id)">
                                                    {{ data.data.controller_account_name }}
                                                </td>
                                                <td class="text-gray-800 cursor-pointer" @click="updateItem(data.data.id)">
                                                    {{ data.data.primary_person }}
                                                </td>
                                                <td class="text-gray-800 cursor-pointer" @click="updateItem(data.data.id)">
                                                    {{ data.data.company_gsm }}
                                                </td>
                                                <td class="text-gray-800 cursor-pointer" @click="updateItem(data.data.id)">
                                                    {{ data.data.company_email }}
                                                </td>
                                                <td class="text-gray-800 cursor-pointer" @click="updateItem(data.data.id)">
                                                    {{ data.data.company_country_code }}
                                                </td>
                                                <td class="text-gray-800 cursor-pointer" @click="updateItem(data.data.id)">
                                                    {{ data.data.system_access_name }}
                                                </td>
                                                <td class="text-gray-800 cursor-pointer" @click="updateItem(data.data.id)">
                                                    {{ data.data.controller_access_name }}
                                                </td>
                                                <td class="text-gray-800 cursor-pointer text-end" @click="updateItem(data.data.id)">
                                                    {{ previewDate(data.data.created_at) }}
                                                </td>
                                            </template> 
                                    </DxDataGrid>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="modal fade" id="kt_modal_add_licence_parameter" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                    <div class="modal-header" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-11">
                            Edit Licence Parameter 
                            <br v-if="licenceForm.parameter_type !== 5">
                            <span v-if="licenceForm.parameter_type !== 5" class="badge badge-lg badge-success"> {{licenceForm.parameter_type_name}} </span>
                            <span v-if="licenceForm.parameter_type !== 5" class="badge badge-lg badge-warning text-dark mx-5"> {{licenceForm.licence_type_name}}  </span>
                            <span v-if="licenceForm.parameter_type !== 5" class="badge badge-lg badge-primary"> {{licenceForm.package_type_name}} </span>                 
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary">
                            <i class="bi bi-gear fs-2 ms-3 mt-1 cursor-pointer float-end"></i> 
                        </div>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeLicenceParameterModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body pt-1" :class="{'overlay overlay-block': this.isParameterSaving == true}">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <form class="form">
                                            <div class="row mb-2">
                                                <label class="col-lg-6 col-form-label fw-bold fs-6">Order</label>
                                                <div class="col-lg-6">
                                                    <input type="number" class="form-control" @focus="$event.target.select()" name="licence_order" v-model="licenceForm.licence_order">
                                                </div>
                                            </div>
                                            <div class="row mb-2" v-if="licenceForm.parameter_type !== 5">
                                                <label class="col-lg-6 col-form-label fw-bold fs-6">Period of Use (Day)/ User Limit</label>
                                                <div class="col-lg-6">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">                                                            
                                                            <input type="number" class="form-control" @focus="$event.target.select()" name="period_day" v-model="licenceForm.period_day">
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input type="number" class="form-control" @focus="$event.target.select()" name="user_count" v-model="licenceForm.user_count">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2" v-if="licenceForm.parameter_type !== 5">
                                                <label class="col-lg-6 col-form-label fw-bold fs-6">Plan Price Monthly/Annual</label>
                                                <div class="col-lg-6">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <CurrencyInput type="text" class="form-control" v-model="licenceForm.price" @blur="onPriceBlur()" @focus="$event.target.select()" />
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <CurrencyInput type="text" class="form-control" v-model="licenceForm.price_annual" @blur="onPriceBlur()" @focus="$event.target.select()" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2" v-if="licenceForm.parameter_type !== 5 && licenceForm.parameter_type !== 3">
                                                <label class="col-lg-6 col-form-label fw-bold fs-6">User Price Monthly/Annual</label>
                                                <div class="col-lg-6">                                                    
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <CurrencyInput type="text" class="form-control" v-model="licenceForm.user_price" @blur="onPriceBlur()" @focus="$event.target.select()" />
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <CurrencyInput type="text" class="form-control" v-model="licenceForm.user_price_annual" @blur="onPriceBlur()" @focus="$event.target.select()" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2" v-if="licenceForm.parameter_type !== 5">
                                                <label class="col-lg-6 col-form-label fw-bold fs-6">Add VAT? / Rate</label>
                                                <div class="col-lg-6">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input class="form-check-input mt-3" type="checkbox" id="isAddVat" v-model="licenceForm.isAddVat">
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <CurrencyInput type="text" class="form-control" v-model="licenceForm.vat_rate" @blur="onPriceBlur()" @focus="$event.target.select()" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2" v-if="licenceForm.parameter_type !== 5 && licenceForm.parameter_type !== 3">
                                                <label class="col-lg-6 col-form-label fw-bold fs-6">Is Company Inquiry Active? / Daily Limit </label>
                                                <div class="col-lg-6">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input class="form-check-input mt-3" type="checkbox" id="isCompanyInquiryActive" v-model="licenceForm.isCompanyInquiryActive">
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input type="number" class="form-control" @focus="$event.target.select()" name="company_inquiry_rate_limit" v-model="licenceForm.company_inquiry_rate_limit">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2" v-if="licenceForm.parameter_type !== 5 && licenceForm.parameter_type !== 3">
                                                <label class="col-lg-6 col-form-label fw-bold fs-6">Is Address Inquiry Active? / Daily Limit </label>
                                                <div class="col-lg-6">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input class="form-check-input mt-3" type="checkbox" id="isAddressInquiryActive" v-model="licenceForm.isAddressInquiryActive">
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input type="number" class="form-control" @focus="$event.target.select()" name="address_inquiry_rate_limit" v-model="licenceForm.address_inquiry_rate_limit">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2" v-if="licenceForm.parameter_type !== 5 && licenceForm.parameter_type !== 3">
                                                <label class="col-lg-6 col-form-label fw-bold fs-6">Is Documents Included? / Limit </label>
                                                <div class="col-lg-6">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input class="form-check-input mt-3" type="checkbox" id="isDocumentsIncluded" v-model="licenceForm.isDocumentsIncluded">
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input type="number" class="form-control" @focus="$event.target.select()" name="document_limit" v-model="licenceForm.document_limit">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2" v-if="licenceForm.parameter_type !== 5 && licenceForm.parameter_type !== 3">
                                                <label class="col-lg-6 col-form-label fw-bold fs-6">Is Liabilities Included?</label>
                                                <div class="col-lg-6">
                                                    <input class="form-check-input mt-3" type="checkbox" id="isLiabilitiesIncluded" v-model="licenceForm.isLiabilitiesIncluded">
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-6 col-form-label fw-bold fs-6">Description</label>
                                                <div class="col-lg-6">
                                                    <textarea type="text" class="form-control" rows="3" name="description" v-model="licenceForm.description" :maxlength="255"></textarea>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-5">
                                <div class="d-grid gap-2"> 
                                    <button type="button" class="btn btn-primary btn-lg float-end"
                                        @click="this.editLicenceParameter()" :disabled="this.isModalButtonDisabled">
                                        <span class="indicator-label">Update</span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isParameterSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="kt_modal_add_api_key" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                    <div class="modal-header" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-10">
                            <span v-if="this.apiKeyModalType == 'editItem'">Edit API Key </span>
                            <span v-if="this.apiKeyModalType == 'addItem'">Add API Key </span>                    
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeApiKeyModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body pt-1" :class="{'overlay overlay-block': this.isParameterSaving == true}">
                        <div class="row">
                            <div class="tab-content" id="myTabContent">
                                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                                    <div class="p-10"> 
                                        <div class="row">
                                            <div class="col-lg-12 d-flex flex-column flex-row-auto">
                                                <form id="kt_ecommerce_settings_general_form"
                                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                    <div class="fv-row mb-6 row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                API Type
                                                            </label>
                                                            <Select2 v-model="apiKeyForm.type" :options="apiKeyTypeList" :settings="{width: '100%'}" />
                                                        </div>
                                                        <div class="col-lg-12" v-if="apiKeyForm.type !== 1">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                API Sub Type
                                                            </label>
                                                            <Select2 v-model="apiKeyForm.sub_type" :options="apiSubTypeList" :settings="{width: '100%'}" />
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row fv-plugins-icon-container">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    <span>API Url</span>
                                                                </label>
                                                                <input type="text" class="form-control" name="apiUrl" v-model="apiKeyForm.api_url">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                <span>API Key</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="apiKey" v-model="apiKeyForm.api_key">
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    <span>Client ID</span>
                                                                </label>
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" name="clientID" v-model="apiKeyForm.client_id">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    <span>Primary Key</span>
                                                                </label>
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" name="primaryKey" v-model="apiKeyForm.primary_key">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-5" for="isActive">
                                                                Is Active
                                                            </label>
                                                            <div class="form-check mt-3 mb-2">
                                                                <input class="form-check-input" type="checkbox" value="" id="isActive" v-model="apiKeyForm.isActive">
                                                            </div> 
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-5" for="isDefault">
                                                                Is Default
                                                            </label>
                                                            <div class="form-check mt-3 mb-2">
                                                                <input class="form-check-input" type="checkbox" value="" id="isDefault" v-model="apiKeyForm.isDefault">
                                                            </div> 
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                    <span>Description</span>
                                                                </label>
                                                            <div class="col-lg-12">
                                                                <textarea type="text" class="form-control" rows="3" name="description" v-model="apiKeyForm.description" :maxlength="255"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="col-lg-12 mt-0">
                                                <div class="" :class="{'d-grid gap-2': this.apiKeyModalType == 'addItem'}"> 
                                                    <button v-if="this.apiKeyModalType == 'addItem'" type="button" class="btn btn-lg btn-success " @click="this.saveApiKey()" data-kt-indicator="on">
                                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                        </svg>
                                                        </span>
                                                        <strong v-if="!this.isProgressing"> Save API Key </strong> 
                                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span> 
                                                    </button>
                                                    <button v-if="this.apiKeyModalType == 'editItem'" type="button" class="btn btn-lg btn-primary float-end" @click="this.updateApiKey()" data-kt-indicator="on">
                                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                        </svg>
                                                        </span>
                                                        <strong v-if="!this.isProgressing"> Update API Key </strong> 
                                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span> 
                                                    </button>
                                                    <button type="button" class="btn btn-danger float-start btn-lg" v-if="this.apiKeyModalType == 'editItem'" 
                                                        @click="this.deleteApiKey(this.apiKeyForm.id)">
                                                        <strong>Delete</strong> 
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isParameterSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
import moment from 'moment'; 
import MailSettings from '../Modals/MailSettings.vue';
 
export default {
  name: "ControllerAccountUpdate",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, MailSettings, DxScrolling
    },
  data() {
    return {
        userData: this.$getUser(),
        isProgressing: false,
        isListProgressing: false,
        isOpenActionsDropdown: false,
        isHide: true,
        form: {
            id: null,
            address_id: '-1',
            company_name: null,
            company_vkn: null,
            primary_person: null,
            company_gsm: null,
            company_landphone: null,
            company_email: null, 
            company_address: null,
            company_city: null,
            company_town: null,
            company_state: null,
            company_region: null,
            company_region_select: '-1',
            company_postcode: null,
            company_country_code: '-1',        
            licence_id: 0,
            currency_code: '-1',
            system_account_id: null,
            online_accounting: false,
            company_online_accounting_default: false,
            phone_country_code: null,
            licence_bank_description: null,
            is_insurance_module_active: 0,
            currency_search_type: 2,
            is_consolidation_active: 0
        },
        regionList: [],
        countryList: [],
        currencyList: [],
        addressList: [{ id: '-1', text: 'Select an option'}],
        subOrMainList: [{ id: 0, text: 'Main'}, { id: 1, text: 'Sub'}],
        parameterTypeList: [{ id: 0, text: 'New Account'}, { id: 1, text: 'Extend To Try'}, { id: 2, text: 'Licenced'}, { id: 3, text: 'Add User'} ],
        licenceTypeList: [{ id: 0, text: 'Trial'}, { id: 1, text: 'Licence'}],
        packageTypeList: [{ id: 0, text: 'No Accounting'}, { id: 1, text: 'With Accounting'}, { id: 2, text: 'Add Accounting'}, { id: 3, text: 'Free Accounting'}],
        licenceParameterList: [],
        activeView: "General", 
        activeTabView: "MailSettings", 
        mailSettingsForm: { 
            id: null,
            username: null,
            password: null,
            sender: null,
            smtp: null,
            port:0,
            ssl: false
        },
        licenceForm: {
            id: null,
            user_count: 0,
            period_day: 0,
            price: 0.00,
            price_annual: 0.00,
            user_price: 0.00,
            user_price_annual: 0.00,
            licence_order: 0,
            isLiabilitiesIncluded: false,
            isDocumentsIncluded: false,
            document_limit: 0,
            is_documents_included: 0,
            is_liabilities_included: 0,
            description: null,
            is_active: 0,
            licence_type_name: null,
            package_type_name: null,
            parameter_type_name: null,
            parameter_type: 0,
            vat_rate: 0,
            is_add_vat: 0,
            isAddVat: false,
            is_company_inquiry_active: 0,
            isCompanyInquiryActive: false,
            company_inquiry_rate_limit: 0,
            is_address_inquiry_active: 0,
            isAddressInquiryActive: false,
            address_inquiry_rate_limit: 0
        },
        apiKeyForm: {
            controller_id: null,
            api_key: null,
            client_id: null,
            primary_key: null,
            api_url: null,
            type: 1,
            sub_type: 1,
            is_active: 0,
            isActive: false,
            is_default: 0,
            isDefault: false,
            description: null,
            id: null
        },
        isModalButtonDisabled: false,
        isParameterSaving: false,
        modalType: 'addParameter',
        reportDesignList: [],
        controllerReportDesignList: [],
        designForm: {
            controller_id: null,
            report_design_id: '-1',
            is_default: 0
        },
        apiKeyTypeList:[
            {
                id: 1,
                text: "Company Inquiry"
            },
            {
                id: 2,
                text: "Address Inquiry"
            },
            {
                id: 3,
                text: "Address Inquiry By Address Code"
            }
        ],
        apiSubTypeList:[
            {
                id: 1,
                text: "OS Places"
            },
            {
                id: 2,
                text: "getAddress"
            }
        ],
        apiKeyFormList:[],
        apiKeyModalType: 'addParameter',
        rateForm: {
            rate_type_id: '-1'
        },
        dataImportForm: {
            category: 0,
            template_id: '-1'
        },
        controllerRateTypeList: [],
        rateList: [],
        dataImportCategoryList: [
            { id: 0, text: 'Select an option'},
            { id: 1, text: 'Accounts'},
            { id: 2, text: 'Bank Statement'},
            { id: 5, text: 'Collection'},
            { id: 3, text: 'Insurance Policy'},
            { id: 4, text: 'Invoice'},
        ],
        dataImportTemplateList: [{ id: 0, text: 'Select an option'}],
        taxMethodList: [{id: 1, text: 'Producer'}, {id: 2, text: 'Insurer'}],
        taxForm: {
            tax_method: 1,
            sub_tax_1_method: 1,
            sub_tax_2_method: 1,
            sub_tax_3_method: 1,
        },
        currencySearchlist: [
            {id: 1, text: "Local currency / Other currency (Dividing by Foreign Exchange Value)"},
            {id: 2, text:  "Other currency / Local currency (Multiplying by Foreign Exchange Value)"}
        ],
        controllerDataImportList: [],
        companyList: [],
        htmlEditorKey: ""
    };
  },
  computed: {
		isValidEmail() {
			return (this.form.company_email == null || this.form.company_email == '' || this.form.company_email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.company_email);
		},
        isValidEmails() {
			return (this.mailSettingsForm.sender == null || this.mailSettingsForm.sender == '' || this.mailSettingsForm.sender == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.mailSettingsForm.sender);
		},
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        }
  },
  methods: {
    getData() {
        this.$Progress.start();
        this.$setSessionStorage('refreshList', true);
        const queryParameter = {
            id: this.$getSessionStorage("record_id"),
            session_id: this.$getUser().session_id,
        };
        axios.post('/api/Accounts/GetControllerAccount', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {
            response.data.company_name = this.$unlock(response.data.company_name);
            response.data.company_vkn = this.$unlock(response.data.company_vkn);
            response.data.primary_person = this.$unlock(response.data.primary_person);
            response.data.company_gsm = this.$unlock(response.data.company_gsm);
            response.data.company_landphone = this.$unlock(response.data.company_landphone);
            response.data.company_email = this.$unlock(response.data.company_email);
            response.data.company_address = this.$unlock(response.data.company_address);
            response.data.company_city = this.$unlock(response.data.company_city);
            response.data.company_town = this.$unlock(response.data.company_town);
            response.data.company_state = this.$unlock(response.data.company_state);
            response.data.company_region = this.$unlock(response.data.company_region);
            response.data.company_postcode = this.$unlock(response.data.company_postcode);
            response.data.company_country_code = this.$unlock(response.data.company_country_code);
            response.data.phone_country_code = this.$unlock(response.data.phone_country_code);
            response.data.currency_code = this.$unlock(response.data.currency_code);
            this.data = response.data;
            this.form = { 
                id: response.data.id,  
                company_name: response.data.company_name,
                company_vkn: response.data.company_vkn,
                primary_person: response.data.primary_person,
                company_gsm: response.data.company_gsm,
                company_landphone: response.data.company_landphone,
                company_email: response.data.company_email,
                company_address: response.data.company_address,
                company_city: response.data.company_city,
                company_town: response.data.company_town,
                company_state: response.data.company_state,
                company_region: response.data.company_region,
                company_region_select: (response.data.company_region == '' || response.data.company_region == null) ? '-1' : response.data.company_region,
                company_postcode: response.data.company_postcode,
                company_country_code: (response.data.company_country_code == '' || response.data.company_country_code == null) ? '-1' : response.data.company_country_code,
                activation_date: response.data.activation_date,
                licence_id: response.data.licence_id,
                currency_code: (response.data.currency_code == '' || response.data.currency_code == null) ? '-1' : response.data.currency_code,
                address_id: '-1',
                online_accounting: response.data.online_accounting == 1 ? true : false,
                company_online_accounting_default: response.data.company_online_accounting_default == 1 ? true : false,
                is_insurance_module_active: response.data.is_insurance_module_active == 1 ? true : false,
                phone_country_code: response.data.phone_country_code,
                licence_bank_description: response.data.licence_bank_description,
                currency_search_type: response.data.currency_search_type,
                is_consolidation_active: response.data.is_consolidation_active == 1 ? true : false,
            };
            this.getCurrencies();
            if(this.$route.params.isRedirect == 'true') {
                this.activeView = 'Companies';
                this.getCompanies();
            }
            this.$Progress.finish();
        })        
        .catch(function (error) {
            this.$Progress.finish();
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        });        
    },
    getDocumentDesigner() {
      const parameters = {
        session_id: this.$getUser().session_id        
      };
      axios.post('/api/DocumentDesign/GetDesigns', parameters, {'Content-Type': 'application/json'}).then((response) => { 
        this.reportDesignList = response.data;
        this.reportDesignList.unshift({ id: '-1', text: 'Select an option'});
        this.getControllerReportDesigns();
      })
      .catch(function (error) {
        console.log(error); 
      });
    },
    getCurrencies() {        
        this.$Progress.start();
        const parameters = {
            session_id: this.$getUser().session_id,
            get_access_status: 0
        };  
        
        const requestOne =  axios.post('/api/Lists/Currencies', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Lists/GetCountryList', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Lists/GetRegionList', parameters, {'Content-Type': 'application/json'}); 
        const requestFour =  axios.get('/api/Auth/GetHEK');        
        axios.all([requestOne, requestTwo, requestThree, requestFour]).then(axios.spread((...responses) => {
            this.currencyList = responses[0].data;
            this.countryList = responses[1].data; 
            this.regionList = responses[2].data; 
            this.htmlEditorKey = this.$unlock(responses[3].data);
            this.currencyList.unshift({id: '-1', text: 'Select an option'});
            this.countryList.unshift({id: '-1', text: 'Select an option'});
            this.regionList.unshift({id: '-1', text: 'ALL'});
            this.$Progress.finish();

            this.countryList.forEach(element => {
                if(element.id == this.form.company_country_code) {
                    this.form.phone_country_code = element.phone_country_code;
                }
            });
        }))
        .catch(errors => { 
            console.error(errors); 
            this.$Progress.fail();
        }); 
    },
    saveData(){
      this.isProgressing = true;
      const params = {
        session_id: this.$getUser().session_id,
        id: this.form.id,
        address_id: this.form.address_id,
        company_name: this.form.company_name,
        company_vkn: this.form.company_vkn,
        primary_person: this.form.primary_person,
        company_gsm: this.form.company_gsm,
        company_landphone: this.form.company_landphone,
        company_email: this.form.company_email, 
        company_address: this.form.company_address,
        company_city: this.form.company_city,
        company_town: this.form.company_town,
        company_state: this.form.company_state,
        company_region: this.form.company_country_code == 'GB' ? this.form.company_region_select : this.form.company_region,
        company_region_select: this.form.company_region_select == '-1' ? null : this.form.company_region_select,
        company_postcode: this.form.company_postcode,
        company_country_code: this.form.company_country_code == '-1' ? null : this.form.company_country_code,
        licence_id: this.form.licence_id,
        currency_code: this.form.currency_code == '-1' ? null : this.form.currency_code,
        system_account_id: this.form.system_account_id,        
        online_accounting: this.form.online_accounting == true ? 1 : 0,
        company_online_accounting_default: this.form.company_online_accounting_default == true ? 1 : 0,
        is_insurance_module_active: this.form.is_insurance_module_active == true ? 1 : 0,
        phone_country_code: this.form.phone_country_code,        
        licence_bank_description: this.form.licence_bank_description,
        currency_search_type: this.form.currency_search_type,
        is_consolidation_active: this.form.is_consolidation_active == true ? 1 : 0,
      }; 
      const that = this;
      axios.post('/api/Accounts/UpdateControllerAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){ 
                this.$swal("Success", "Data saved succesfully..!", 'success');    
                this.$setSessionStorage('refreshList', true);
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$setSessionStorage('refreshList', true); 
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
    },
    saveMailSettings(){
        this.isProgressing = true;
        const obj = {
            "username": this.mailSettingsForm.username,
            "password": this.mailSettingsForm.password,
            "sender": this.mailSettingsForm.sender,
            "smtp": this.mailSettingsForm.smtp,
            "port": this.mailSettingsForm.port,
            "ssl": this.mailSettingsForm.ssl
        };
        const mailInfo = JSON.stringify(obj);
        const params = {
            id: this.mailSettingsForm.id,
            session_id:  this.$getUser().session_id,
            data : mailInfo,
            controller_id: this.form.id
        };
        const that = this;
        axios.post('/api/MailSettings/Create', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.$swal("Success", "Data saved succesfully..!", 'success');
                this.mailSettingsForm.id = response.data;
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
    },
    deleteAccount() { 
        this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            icon: 'warning',
            confirmButtonColor: '#f1416c', //'#E53935',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => { 
        if (result.isConfirmed) {
            const queryParameter = {
                id: this.form.id,
                session_id: this.$getUser().session_id,
            };
            axios.post('/api/Accounts/DeleteControllerAccount', queryParameter, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(!response.data.error){
                    this.$swal("Deleted", "Data deleted successfully..!", 'warning'); 
                    this.$setSessionStorage('refreshList', true);
                    this.$router.push({ name: 'ControllerAccountList' });
                    } 
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        })  
    },
    deleteControllerAccount() { 
        this.$swal.fire({
            title: 'Are you sure you want to delete this all controller data and this controller?',
            icon: 'warning',
            confirmButtonColor: '#f1416c', //'#E53935',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete Controller!'
        }).then((result) => { 
        if (result.isConfirmed) {
            const params = {
            session_id: this.$getUser().session_id,
            controller_id: this.form.id,
            };
            axios.post('/api/Accounts/DeletePermanentlyControllerAccount', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                    this.$swal("Deleted", "Data deleted successfully..!", 'warning'); 
                    this.$setSessionStorage('refreshList', true);
                    this.$router.push({ name: 'ControllerAccountList' });
                    } 
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        })  
    },
    onChangeCountry(event) {
        this.form.company_region_select = '-1';
        this.form.company_region = null;
        if(event == null || event == '' || event == undefined) {
            this.countryList.forEach(element => {
                if(element.id == this.form.company_country_code) {
                    this.form.phone_country_code = element.phone_country_code;
                }
            });
        } else this.form.phone_country_code = event.phone_country_code;
    },
    openEditLicenceParameterModal(itemId) {
        this.isParameterSaving = false;
        this.isModalButtonDisabled = false;        
        const params = {
            session_id: this.$getUser().session_id,
            id: itemId
        };
        axios.post('/api/LicenceParameter/GetById', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.licenceForm = {
                id: response.data.id,
                parameter_type: response.data.parameter_type,
                licence_type_name: response.data.licence_type_name,
                package_type_name: response.data.package_type_name,
                parameter_type_name: response.data.parameter_type_name,
                user_count: response.data.user_count,
                period_day: response.data.period_day,
                price: response.data.price.replace(',', ''),
                price_annual: response.data.price_annual.replace(',', ''),
                user_price: response.data.user_price.replace(',', ''),
                user_price_annual: response.data.user_price_annual.replace(',', ''),
                licence_order: response.data.licence_order,
                isLiabilitiesIncluded: response.data.is_liabilities_included == 1 ? true : false,
                isDocumentsIncluded: response.data.is_documents_included == 1 ? true : false,
                document_limit: response.data.document_limit,
                is_documents_included: response.data.is_documents_included,
                is_liabilities_included: response.data.is_liabilities_included,
                description: response.data.description,
                is_sub_or_main: response.data.is_sub_or_main,
                is_active: response.data.is_active,                
                vat_rate: response.data.vat_rate,
                is_add_vat: response.data.is_add_vat,
                isAddVat: response.data.is_add_vat == 1 ? true : false,
                is_company_inquiry_active: response.data.is_company_inquiry_active,
                isCompanyInquiryActive: response.data.is_company_inquiry_active == 1 ? true : false,
                company_inquiry_rate_limit: response.data.company_inquiry_rate_limit,
                is_address_inquiry_active: response.data.is_address_inquiry_active,
                isAddressInquiryActive: response.data.is_address_inquiry_active == 1 ? true : false,
                address_inquiry_rate_limit: response.data.address_inquiry_rate_limit
            };
        })
        .catch(function (error) {
            console.log(error);
        });
    },    
    getLicenceParameters() {
        this.isListProgressing = true;
        const params = {
            session_id: this.$getUser().session_id,
            controller_id: this.form.id
        };
        const that = this;
        axios.post('/api/LicenceParameter/Get', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.licenceParameterList = response.data;
        })
        .catch(function (error) {
            console.log(error);
            that.isListProgressing = false;
        })
        .finally(function () {
            that.isListProgressing = false;
        });
    },
    editLicenceParameter() {
        this.licenceParameterList.forEach((item) => { 
            if (item.id == this.licenceForm.id) {
                this.isModalButtonDisabled = true;
                this.isParameterSaving = true;
                const that = this;
                const params = this.licenceForm;    
                params.session_id = this.$getUser().session_id;
                params.is_documents_included = params.isDocumentsIncluded ? 1 : 0;
                params.is_liabilities_included = params.isLiabilitiesIncluded ? 1 : 0;
                params.is_add_vat = params.isAddVat ? 1 : 0;
                params.is_active = params.is_active ? 1 : 0;
                params.is_company_inquiry_active = params.isCompanyInquiryActive ? 1 : 0;
                params.is_address_inquiry_active = params.isAddressInquiryActive ? 1 : 0;
                axios.post('api/LicenceParameter/Update', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.getLicenceParameters();
                        this.$refs.closeLicenceParameterModal.click();
                    }
                    this.isParameterSaving = false;
                })
                .catch(function (error) {
                    this.isParameterSaving = false;
                    console.log(error);
                })
                .finally(function () { 
                    setTimeout(() => {
                        that.isParameterSaving = false;
                        that.isModalButtonDisabled = false;
                    }, 500);
                });
            }
        });  
    },
    onChangeIsActive(item) {
        this.$swal.fire({
            title: item.is_active ? "Are you sure you want to activate this item?" : "Are you sure you want to deactivate this item?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const params = item;
                params.is_active = params.is_active ? 1 : 0;                
                params.session_id = this.$getUser().session_id;
                axios.post('api/LicenceParameter/Update', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        item.is_active = item.is_active == 1 ? true : false;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    item.is_active = item.is_active == 1 ? true : false;
                });
            }
            else{
                item.is_active = !item.is_active;
            }
        });
    },
    addReportDesign(){ 
        const params = {
            session_id: this.$getUser().session_id,            
            is_default: 0,
            controller_id: this.form.id,
            report_design_id: this.designForm.report_design_id,
            id: null
        } 
        axios.post("/api/DocumentDesign/AddDesignToController", params, {'Content-Type': 'application/json'}).then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                this.getControllerReportDesigns(); 
                this.designForm.report_design_id = '-1'
            }
        })
        .catch(function (error) {
            console.log(error); 
        });
    },
    onChangeIsDefault(item){
        this.$swal.fire({
            title: item.is_default ? "Are you sure you want to set this item as default?" : "Are you sure you want to remove default assignment?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {                
                const params = {
                    session_id: this.$getUser().session_id,            
                    is_default: item.is_default ? 1 : 0,
                    id: item.id
                } 
                axios.post("/api/DocumentDesign/UpdateControllerReportDesign", params, {'Content-Type': 'application/json'}).then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                        this.getControllerReportDesigns();
                    }
                })
                .catch(function (error) {
                    item.is_default = !item.is_default;
                    console.log(error); 
                });
            }
            else{
                item.is_default = !item.is_default;
            }
        });
    },
    getControllerReportDesigns() {
        this.isListProgressing = true;
        const parameters = {
          session_id: this.$getUser().session_id,
          controller_id: this.form.id
        };  
        const that = this;
        axios.post('/api/DocumentDesign/GetControllerReportDesigns', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.controllerReportDesignList = response.data;
        })
        .catch(function (error) {
            alert(error);
            that.isListProgressing = false;
        })
        .finally(function () {
            that.isListProgressing = false;
        });
    },
    deleteDesign(designId){ 
      this.$swal.fire({
        title: 'Are you sure you want to delete this design?',
        confirmButtonColor: '#f1416c',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        icon: 'warning'
      }).then((result) => { 
        if (result.isConfirmed) {
            const parameters = {
                session_id: this.$getUser().session_id,
                id: designId
            };
         
            axios.post('/api/DocumentDesign/DeleteControllerDesign', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    new Swal( { icon: 'error', title: 'Deleted...', text: 'Data deleted..!' });
                    this.getControllerReportDesigns(); 
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
      })    
    },
    onPriceBlur() {
        this.licenceForm.price = (this.licenceForm.price == '' || this.licenceForm.price == null) ? 0.00 : this.licenceForm.price;
        this.licenceForm.price_annual = (this.licenceForm.price_annual == '' || this.licenceForm.price_annual == null) ? 0.00 : this.licenceForm.price_annual;
        this.licenceForm.user_price = (this.licenceForm.user_price == '' || this.licenceForm.user_price == null) ? 0.00 : this.licenceForm.user_price;
        this.licenceForm.user_price_annual = (this.licenceForm.user_price_annual == '' || this.licenceForm.user_price_annual == null) ? 0.00 : this.licenceForm.user_price_annual;
        this.licenceForm.vat_rate = (this.licenceForm.vat_rate == '' || this.licenceForm.vat_rate == null) ? 0.00 : this.licenceForm.vat_rate;
    },
    openCreateApiKeyModal(itemID, modalType){
        this.apiKeyModalType = modalType;
        this.clearApiKeyForm();
        this.apiKeyForm.controller_id = itemID;
    },
    saveApiKey(){
      const params = this.apiKeyForm; 
      const that = this;      
      this.isParameterSaving = true;
      params.session_id = this.$getUser().session_id;
      params.is_active = params.isActive == true ? 1 : 0;
      params.is_default = params.isDefault == true ? 1 : 0;
      axios.post('/api/Accounts/CreateApiKey', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.getApiKeys();
                this.$refs.closeApiKeyModal.click();
            }
            this.isParameterSaving = false;

        })
        .catch(function (error) {
            console.log(error);
            this.$setSessionStorage('refreshList', true); 
        })
        .finally(function () { 
            setTimeout(() => {
                that.isParameterSaving = false;
            }, 500);
        });   
    },
    getApiKeys() {
        this.isListProgressing = true;
        const params = {
            session_id: this.$getUser().session_id,
            controller_id: this.form.id
        };
        const that = this;
        axios.post('/api/Accounts/GetApiKeys', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.apiKeyFormList = response.data;
            this.apiKeyFormList.forEach(element => {
                element.controller_id = this.$unlock(element.controller_id);
                element.api_key = this.$unlock(element.api_key);
                element.client_id = this.$unlock(element.client_id);
                element.primary_key = this.$unlock(element.primary_key);
                element.api_url = this.$unlock(element.api_url);
            });
        })
        .catch(function (error) {
            console.log(error);
            that.isListProgressing = false;
        })
        .finally(function () {
            that.isListProgressing = false;
        });
    },
    openEditApiKey(item, modalType){
        this.apiKeyModalType = modalType;
            this.isParameterSaving = false;
            this.isModalButtonDisabled = false;        
            const params = {
                session_id: this.$getUser().session_id,
                id: item
            };
            axios.post('/api/Accounts/GetByApiKey', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                this.apiKeyForm = {
                    controller_id: this.$unlock(response.data.controller_id),
                    api_key: this.$unlock(response.data.api_key),
                    client_id: this.$unlock(response.data.client_id),
                    primary_key: this.$unlock(response.data.primary_key),
                    api_url: this.$unlock(response.data.api_url),
                    type: response.data.type,
                    sub_type: response.data.sub_type,
                    is_active: response.data.is_active,
                    isActive: response.data.is_active == 1 ? true : false,
                    is_default: response.data.is_default,
                    isDefault: response.data.is_default == 1 ? true : false,
                    description: response.data.description,
                    id: response.data.id
                };
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    clearApiKeyForm(){
        this.apiKeyForm = {
            controller_id: null,
            api_key: null,
            client_id: null,
            primary_key: null,
            api_url: null,
            type: 1,
            sub_type: 1,
            is_active: 0,
            isActive: false,
            is_default: 0,
            isDefault: false,
            description: null
        }
    },
    updateApiKey(){
      const params = this.apiKeyForm; 
      const that = this;
      
      this.isParameterSaving = true;

      params.session_id = this.$getUser().session_id;
      params.is_active = params.isActive == true ? 1 : 0;
      params.is_default = params.isDefault == true ? 1 : 0;
      axios.post('/api/Accounts/UpdateApiKey', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.getApiKeys();
            this.$refs.closeApiKeyModal.click();
            }
            this.isParameterSaving = false;
        })
        .catch(function (error) {
            console.log(error);
            this.$setSessionStorage('refreshList', true); 
        })
        .finally(function () { 
            setTimeout(() => {
                that.isParameterSaving = false;
            }, 500);
        });   
    },
    deleteApiKey(itemID){
        this.$swal.fire({
            title: 'Are you sure you want to delete this API Key?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: 'warning'
        }).then((result) => { 
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    id: itemID
                };
                axios.post('/api/Accounts/DeleteApiKey', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                        this.getApiKeys(); 
                        this.$refs.closeApiKeyModal.click();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        })    
    },
    getRateLists() {
      const parameters = {
        session_id: this.$getUser().session_id        
      };
      axios.post('/api/Lists/RateTypesAll', parameters, {'Content-Type': 'application/json'}).then((response) => { 
        this.rateList = response.data;
        this.rateList.unshift({id: '-1', text: 'Select an option'});
        this.getRateTypes();
      })
      .catch(function (error) {
        console.log(error); 
      });
    },
    getRateTypes() {
        const parameters = {
            session_id: this.$getUser().session_id,
            controller_id: this.form.id
        };  
        const that = this;
        this.isListProgressing = true;
        axios.post('/api/Accounts/GetDefaultRateLists', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.controllerRateTypeList = response.data;
           }
        })
        .catch(function (error) {
           console.log(error);
           that.isListProgressing = false;
        })
        .finally(function () {
            that.isListProgressing = false;
        });
    },
    addRateTypeToList(){
        const parameters = {
            session_id: this.$getUser().session_id,
            is_default: 0,
            controller_id: this.form.id,
            rate_type_id: this.rateForm.rate_type_id !== '-1' ? this.rateForm.rate_type_id : null
        };  
        axios.post('/api/Accounts/AddRateListToController', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.getRateTypes();
              this.rateForm.rate_type_id = '-1';
           }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onChangeDefaultRateList(item){
        this.$swal.fire({
            title: item.is_default ? "Are you sure you want to set this item as default?" : "Are you sure you want to remove default assignment?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {                
                const params = {
                    session_id: this.$getUser().session_id,            
                    is_default: item.is_default ? 1 : 0,
                    id: item.id
                } 
                axios.post("/api/Accounts/UpdateControllerDefaultRate", params, {'Content-Type': 'application/json'}).then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                        this.getRateTypes();
                    }
                })
                .catch(function (error) {
                    item.is_default = !item.is_default;
                    console.error(error); 
                });
            }
            else{
                item.is_default = !item.is_default;
            }
        });
    },
    deleteRateList(rateId){ 
      this.$swal.fire({
        title: 'Are you sure you want to delete this rate list?',
        confirmButtonColor: '#f1416c',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        icon: 'warning'
      }).then((result) => { 
        if (result.isConfirmed) {
          const parameters = {
            session_id: this.$getUser().session_id,
            id: rateId
          };
         
        axios.post('/api/Accounts/DeleteRateList', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.getRateTypes(); 
            }
          })
          .catch(function (error) {
              console.log(error);
          });
        }
      })    
    },
    getPolicyParameters() {
        const parameters = {
          session_id: this.$getUser().session_id,
          controller_id: this.form.id
        };  
        axios.post('/api/Accounts/GetPolicyParameters', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           this.taxForm = response.data;
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    savePolicyParameters() {
        const parameters = {
          session_id: this.$getUser().session_id,
          controller_id: this.form.id,
          tax_method: this.taxForm.tax_method,
          sub_tax_1_method: this.taxForm.sub_tax_1_method,
          sub_tax_2_method: this.taxForm.sub_tax_2_method,
          sub_tax_3_method: this.taxForm.sub_tax_3_method,
        };  
        axios.post('/api/Accounts/SavePolicyParameters', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){              
                this.$swal("Success", "Data saved succesfully..!", 'success');   
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    getDataImportTemplates() {
        const params = {
          session_id: this.$getUser().session_id,
          category: this.dataImportForm.category
        };
        axios.post('api/Office/GetDataImportTemplates', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){      
                this.dataImportTemplateList = response.data;
                this.dataImportTemplateList.unshift({id: '-1', text: 'Select an option'});
                this.getControllerImportTemplates();
                this.dataImportForm.template_id = '-1';
            }
        });
    },
    addDataImportToList() {
        const parameters = {
            session_id: this.$getUser().session_id,
            is_default: 0,
            controller_id: this.form.id,
            template_id: this.dataImportForm.template_id == '-1' ? null : this.dataImportForm.template_id,
            id: null
        };  
        axios.post('/api/Office/AddImportTemplateToController', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.getControllerImportTemplates();
                this.dataImportForm.template_id = '-1';
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    getControllerImportTemplates() {
        const parameters = {
            session_id: this.$getUser().session_id,
            controller_id: this.form.id
        };  
        const that = this;
        this.isListProgressing = true;
        axios.post('/api/Office/GetControllerImportTemplates', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.controllerDataImportList = response.data;
            }
        })
        .catch(function (error) {
           console.log(error);
           that.isListProgressing = false;
        })
        .finally(function () {
            that.isListProgressing = false;
        });
    },
    onChangeImportTemplate(item){
        this.$swal.fire({
            title: item.is_default ? "Are you sure you want to set this item as default?" : "Are you sure you want to remove default assignment?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {                
                const params = {
                    session_id: this.$getUser().session_id,            
                    is_default: item.is_default ? 1 : 0,
                    id: item.id
                } 
                axios.post("/api/Office/UpdateDefaultImportTemplate", params, {'Content-Type': 'application/json'}).then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                        this.getControllerImportTemplates();
                    }
                })
                .catch(function (error) {
                    item.is_default = !item.is_default;
                    console.error(error); 
                });
            }
            else{
                item.is_default = !item.is_default;
            }
        });
    },
    deleteImportTemplate(recordId){ 
      this.$swal.fire({
        title: 'Are you sure you want to delete this rate list?',
        confirmButtonColor: '#f1416c',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        icon: 'warning'
      }).then((result) => { 
        if (result.isConfirmed) {
          const parameters = {
            session_id: this.$getUser().session_id,
            id: recordId
          };
         
        axios.post('/api/Office/DeleteImportTemplate', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.getControllerImportTemplates(); 
            }
          })
          .catch(function (error) {
              console.log(error);
          });
        }
      })
    },
    getCompanies() {
        const parameters = {
            session_id: this.$getUser().session_id,
            controller_id: this.form.id
        };  
        axios.post('/api/Accounts/CompanyAccounts', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.companyList = response.data;
                this.companyList.forEach(element => {
                    element.company_name = this.$unlock(element.company_name);
                    element.text = this.$unlock(element.text);
                    element.company_vkn = this.$unlock(element.company_vkn);
                    element.primary_person = this.$unlock(element.primary_person);
                    element.company_gsm = this.$unlock(element.company_gsm);
                    element.company_landphone = this.$unlock(element.company_landphone);
                    element.company_email = this.$unlock(element.company_email);
                    element.company_address = this.$unlock(element.company_address);
                    element.company_city = this.$unlock(element.company_city);
                    element.company_town = this.$unlock(element.company_town);
                    element.company_state = this.$unlock(element.company_state);
                    element.company_region = this.$unlock(element.company_region);
                    element.company_postcode = this.$unlock(element.company_postcode);
                    element.company_country_code = this.$unlock(element.company_country_code);
                    element.controller_id = this.$unlock(element.controller_id);
                    element.controller_account_name = this.$unlock(element.controller_account_name);
                    element.system_access_name = this.$unlock(element.system_access_name);
                    element.controller_access_name = this.$unlock(element.controller_access_name);
                });
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    updateItem(id){ 
        this.$setSessionStorage("cp_record_id", id);
        this.$router.push({ name: 'CompanyAccountUpdate', params: { isRedirect: true } });
    },
  },
  created() {
    this.getData();
  },
};
</script>